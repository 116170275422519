<template>
  <div class="social-bar">
    <div class="social-icons-left">
      <a href="https://www.amazon.com/We-All-Die-Twice-mastermind-ebook/dp/B0DGLPY6W3/" target="_blank"
        ><img src="../assets/amazon-24.png" alt="A13Z on Amazon"
      /></a>
      <a href="https://www.amazon.com/dp/B0DFYZYSGD" target="_blank" alt="A13Z on Audible">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          id="Audible"
          width="24"
          height="24"
          style="background-color: transparent"
        >
          <path
            fill="#ffd700"
            d="m24 9.874-12 7.584-12-7.58v2.046L12 19.5l12-7.58z"
            class="colorff9800 svgShape"
          ></path>
          <path
            fill="#ffd700"
            d="m16.706 12.602 1.766-1.114c-1.395-2.115-3.776-3.51-6.476-3.51S6.915 9.369 5.524 11.48h.011c.094-.087.191-.171.289-.254 3.364-2.819 8.227-2.201 10.882 1.376z"
            class="colorff9800 svgShape"
          ></path>
          <path
            fill="#ffd700"
            d="M8.453 13.316a3.693 3.693 0 0 1 2.153-.709c1.29 0 2.445.697 3.24 1.804l1.702-1.076a4.318 4.318 0 0 0-3.555-1.876 4.318 4.318 0 0 0-3.54 1.857zm11.114-2.521 1.83-1.152C19.391 6.546 15.93 4.5 11.996 4.5c-3.882 0-7.373 2.028-9.394 5.139.371-.398.851-.891 1.282-1.235 4.942-3.953 11.933-2.895 15.656 2.354l.027.037z"
            class="colorff9800 svgShape"
          ></path>
        </svg>
      </a>
      <a href="https://t.me/A13Z_a" target="_blank"
        ><img src="../assets/telegram-logo-24.png" alt="A13Z on Telegram"
      /></a>
      <a href="https://x.com/_A13Z_" target="_blank"
        ><img src="../assets/x-logo-24.png" alt="A13Z on X"
      /></a>
    </div>
    <div class="social-icons-right">
      <a
        href="https://calendly.com/contact-a13z/30min"
        target="_blank"
        style="vertical-align: middle"
        ><img
          src="../assets/calendar-24.png"
          alt="A13Z Calendar"
          style="vertical-align: middle"
      /></a>
      <div style="margin-top: 10px">
        <div class="tablet-hide">Book&nbsp;a&nbsp;sponsorship&nbsp;meeting</div>
        <div class="tablet-unhide">Sponsorship&nbsp;meeting</div>
      </div>
    </div>
  </div>
  <header>
    <div class="logo">
      <img src="../assets/A13Z-logo.png" alt="A13Z Logo" />
    </div>
    <nav>
      <ul>
        <li><router-link to="/">Home</router-link></li>
        <div class="tablet-unhide">
          <li>
            <router-link to="/sponsor">Sponsor</router-link>
          </li>
        </div>
        <div class="tablet-hide">
          <li>
            <router-link to="/sponsor">Sponsor&nbsp;this&nbsp;book</router-link>
          </li>
        </div>
        <li class="mobile-hide"><router-link to="/nft">NFTs</router-link></li>
        <li><router-link to="/about">About</router-link></li>
        <li><router-link to="/contact">Contact</router-link></li>
        <li class="mobile-hide"><router-link to="/AdvancedReviewCopy">Reader Review</router-link></li>
        <div class="tablet-hide">
          <li>
            <div class="highlight">
              <router-link
                to="/free-excerpt"
                active-class="active"
                exact-active-class="exact-active"
              >
                Free&nbsp;Excerpt</router-link
              >
            </div>
          </li>
        </div>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "MenuComponent",
};
</script>
<style src="../a13z.css" scoped></style>
